import { isValidPhoneNumber } from 'react-phone-number-input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

let data = {

};
const validation = ({ value, password, type, currentCode }) => {
    switch (type) {
        case "zip_code":
            let zip_code_valid = (value.length > 0 && value.length < 4 || value.length === 0) ? false : true;
            return {
                valid: username_valid,
                value
            };
        case "text":
            let username_valid = (value.length > 0 && value.length < 4 || value.length === 0) ? false : true;
            return {
                valid: username_valid,
                value
            };
        case "email":
            let email_valid = (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && value.length > 0) || value.length === 0 ? false : true;
            return {
                valid: email_valid,
                value
            };
        case "phone_number":
            if (getCountries().includes(currentCode) && getCountryCallingCode(currentCode) === value?.substring(1) || value === undefined) {
                return {
                    valid: true,
                    value
                };
            } else {
                let phone_valid = value && isValidPhoneNumber(value) ? true : false;
                return {
                    valid: phone_valid,
                    value
                };
            }


        case "password":
            let password_valid = (value.length > 0 && value.length < 6 || value.length === 0) ? false : true;
            return {
                valid: password_valid,
                value
            };
        case "confirm_password":
            let confirm_password_valid = true;
            if (value !== password) {
                confirm_password_valid = false;
            }
            return {
                valid: confirm_password_valid,
                value
            };
        case "code":
            let code_valid = value[10] !== "_";
            return {
                valid: code_valid,
                value
            };
        case "year":
            var date_now = new Date();
            var y = date_now.getFullYear().toString().substring(2, 4);

            return {
                valid: +value >= y,
                value
            };
        case "month":
            var date_now = new Date();
            var m = date_now.getMonth() + 1;
            return {
                valid: +value <= 12 && +value >= 1,
                value
            };
        case "cvc":
            var date_now = new Date();
            var m = date_now.getMonth();
            return {
                valid: value.length === 3,
                value
            };
        case "number":
            const newValue = value.split('').filter(item => item !== ' ' && item !== '-').join('');
            return {
                valid: newValue.length === 16,
                value
            };
        default:
            data;
    }
    return data;
};
export default validation;